.children-component {
    ul {
        margin-left: -20px;
    }

    .page-name {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #a8a8a8;
        font-weight: 700;
        color: white;

        @media (max-width: 599px) {
            padding: 40px;
            font-size: medium;
        }
        @media (min-width: 600px) {
            padding: 40px;
            font-size: medium;
        }
        @media (min-width: 900px) {
            padding: 50px;
            font-size: large;
        }
        @media (min-width: 1200px) {
            padding: 90px;
            font-size: x-large;
        }
        @media (min-width: 1800px) {
            padding: 90px;
            font-size: x-large;
        }
    }

    .description {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #747474;

        @media (max-width: 599px) {
            padding: 20px;
            font-size: medium;
        }
        @media (min-width: 600px) {
            padding: 20px;
            font-size: medium;
        }
        @media (min-width: 900px) {
            padding: 30px;
            font-size: large;
        }
        @media (min-width: 1200px) {
            padding: 90px;
            font-size: x-large;
        }
        @media (min-width: 1800px) {
            padding: 90px;
            font-size: x-large;
        }
    }

    .container {
        margin-top: auto;
        margin-bottom: auto;
        .list {
            display: flex;
            justify-content: center;
            color: #747474;
            font-size: medium;
    
            @media (min-width: 1200px) {
                font-size: large;
            }
            @media (min-width: 1800px) {
                font-size: large;
            }
        }
    
        .list-heading {
            display: flex;
            justify-content: center;
            color: #747474;
            font-size: medium;
    
            @media (max-width: 599px) {
                font-size: large;
            }
            @media (min-width: 600px) {
                font-size: large;
            }
            @media (min-width: 900px) {
                font-size: large;
            }
            @media (min-width: 1200px) {
                font-size: x-large;
            }
            @media (min-width: 1800px) {
                font-size: x-large;
            }
        }
    }
}