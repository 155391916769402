.header-component {

    .navbar-text{
        color: #310000;
        border: #310000 solid 1px;
        border-radius: 15px;
        padding: 5px;
    }

    .navbar-brand {
        color: #747474;
        font-size: x-large;
        font-weight: 600;
        
        @media (max-width: 599px) {
            font-size: 1rem;
          }
    }

    #responsive-navbar-nav > div > a.nav-link {
        color: #747474;
    }

    #responsive-navbar-nav > div > a.nav-link.active {
        font-weight: 700;
    }
}