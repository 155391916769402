.practice-areas-component {
    .page-name {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #a8a8a8;
        padding: 90px;
        font-size: x-large;
        font-weight: 700;
        color: white;

        @media (max-width: 599px) {
            padding: 40px;
            font-size: medium;
        }
        @media (min-width: 600px) {
            padding: 40px;
            font-size: medium;
        }
        @media (min-width: 900px) {
            padding: 50px;
            font-size: large;
        }
        @media (min-width: 1200px) {
            padding: 90px;
            font-size: x-large;
        }
        @media (min-width: 1800px) {
            padding: 90px;
            font-size: x-large;
        }
    }

    .description {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #747474;

        @media (max-width: 599px) {
            padding: 20px;
            font-size: medium;
        }
        @media (min-width: 600px) {
            padding: 20px;
            font-size: medium;
        }
        @media (min-width: 900px) {
            padding: 30px;
            font-size: large;
        }
        @media (min-width: 1200px) {
            padding: 90px;
            font-size: x-large;
        }
        @media (min-width: 1800px) {
            padding: 90px;
            font-size: x-large;
        }
    }

    .list {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #747474;
        padding: 40px;
        font-size: larger;
        font-weight: 400;

        .main-bullet-point {
            font-weight: 600;
        }
    }
}