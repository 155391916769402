.divorce-component {
    ul {
        margin-left: -20px;
    }

    .page-name {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #a8a8a8;
        font-weight: 700;
        color: white;

        @media (max-width: 599px) {
            padding: 40px;
            font-size: medium;
        }
        @media (min-width: 600px) {
            padding: 40px;
            font-size: medium;
        }
        @media (min-width: 900px) {
            padding: 50px;
            font-size: large;
        }
        @media (min-width: 1200px) {
            padding: 90px;
            font-size: x-large;
        }
        @media (min-width: 1800px) {
            padding: 90px;
            font-size: x-large;
        }
    }

    .description {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #747474;

        @media (max-width: 599px) {
            padding: 20px;
            font-size: medium;
        }
        @media (min-width: 600px) {
            padding: 20px;
            font-size: medium;
        }
        @media (min-width: 900px) {
            padding: 30px;
            font-size: large;
        }
        @media (min-width: 1200px) {
            padding: 90px;
            font-size: x-large;
        }
        @media (min-width: 1800px) {
            padding: 90px;
            font-size: x-large;
        }
    }

    .divorce-cards {
        background-color: #310000;
        color: #FFF;

        .underline {
            border: 1px solid #FFF;
        }

        .divorce-with-children {
            @media (max-width: 599px) {
                padding-left: 0px;
                padding-right: 0px;
            }
            @media (min-width: 600px) {
                padding-left: 5vw;
                padding-right: 5vw;
            }
            @media (min-width: 900px) {
                padding-left: 16vw;
                padding-right: 16vw;
            }
            @media (min-width: 1200px) {
                padding-left: 10px;
                padding-right: 10px;
            }
            @media (min-width: 1800px) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        @media (max-width: 599px) {
            padding: 10px;
            font-size: small;
            font-weight: 300;
        }
        @media (min-width: 600px) {
            padding: 10px;
            font-size: medium;
            font-weight: 300;
        }
        @media (min-width: 900px) {
            padding: 10px;
            font-size: large;
            font-weight: 300;
        }
        @media (min-width: 1200px) {
            padding: 90px;
            font-size: large;
            font-weight: 300;
        }
        @media (min-width: 1800px) {
            padding: 90px;
            font-size: large;
            font-weight: 300;
        }

        .heading {
            @media (max-width: 599px) {
                font-weight: 500;
            }
            @media (min-width: 600px) {
                font-weight: 500;
            }
            @media (min-width: 900px) {
                font-weight: 700;
            }
            @media (min-width: 1200px) {
                font-weight: 700;
            }
            @media (min-width: 1800px) {
                font-weight: 700;
            }
        }
    }

    .custody-cards {
        background-color: #FFF;
        color: #747474;

        .underline {
            border: 1px solid #FFF;
        }

        @media (max-width: 599px) {
            padding: 10px;
            font-size: small;
            font-weight: 300;
        }
        @media (min-width: 600px) {
            padding: 10px;
            font-size: medium;
            font-weight: 300;
        }
        @media (min-width: 900px) {
            padding: 10px;
            font-size: large;
            font-weight: 300;
        }
        @media (min-width: 1200px) {
            padding: 90px;
            font-size: large;
            font-weight: 300;
        }
        @media (min-width: 1800px) {
            padding: 90px;
            font-size: large;
            font-weight: 300;
        }

        .heading {
            @media (max-width: 599px) {
                font-weight: 500;
            }
            @media (min-width: 600px) {
                font-weight: 500;
            }
            @media (min-width: 900px) {
                font-weight: 700;
            }
            @media (min-width: 1200px) {
                font-weight: 700;
            }
            @media (min-width: 1800px) {
                font-weight: 700;
            }
        }

        .custody-notes {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media (max-width: 599px) {
                font-size: medium;
                font-weight: 500;
            }
            @media (min-width: 600px) {
                font-size: medium;
                font-weight: 500;
            }
            @media (min-width: 900px) {
                font-size: large;
                padding-left: 150px;
                padding-right: 150px;
                font-weight: 500;
            }
            @media (min-width: 1200px) {
                font-size: large;
                padding-left: 10px;
                padding-right: 10px;
                font-weight: 500;
            }
            @media (min-width: 1800px) {
                font-size: x-large;
                padding-left: 10px;
                padding-right: 10px;
                font-weight: 500;
            }
        }
    }
}