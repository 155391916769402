.contact-us-component {
    ul {
        margin-left: -20px;
    }

    .submit {
        width: 200px;
        height: 40px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: green;
        color: white;
    }

    .page-name {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #a8a8a8;
        font-weight: 700;
        color: white;

        @media (max-width: 599px) {
            padding: 40px;
            font-size: medium;
        }
        @media (min-width: 600px) {
            padding: 40px;
            font-size: medium;
        }
        @media (min-width: 900px) {
            padding: 50px;
            font-size: large;
        }
        @media (min-width: 1200px) {
            padding: 90px;
            font-size: x-large;
        }
        @media (min-width: 1800px) {
            padding: 90px;
            font-size: x-large;
        }
    }

    .email {
        padding: 40px;
        color: #747474;
    }

    .form {
        input {
            width: 100%;
        }

        textarea {
            width: 100%;
            height: 100px;
        }

        button {
            width: 100%;
            height: 40px;
            border: 0;
        }

        .valid {
            background-color: green;
            color: white;
        }

        .invalid {
            background-color: lightgray;
            color: black;
        }
    }

    .info {
        font-size: large;
        margin-top: auto;
        margin-bottom: auto;
        color: #747474;
        font-weight: 400;

        .address {
            display: flex;
            justify-content: center;
            padding-top: 40px;
            padding-bottom: 40px;
        }

        .name {
            display: flex;
            justify-content: center;
        }

        .email-label {
            font-size: x-large;
        }

        .numbers {
            display: flex;
            justify-content: center;
            padding-left: 10px;
            padding-right: 10px;
    
            .number-descriptor {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #747474;
                border-radius: 50%;
                width: 35px;
                height: 35px;
                padding-left: 10px;
                padding-right: 10px;
                font-size: x-small;
                font-weight: 600;
            }
    
            .number {
                color: #747474;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 5px;
                padding-right: 10px;
            }
        }
    }

    .map {
        padding: 20px;
    }
}