.home-component {

  .photo-panel {

    background-color: #a8a8a8;
    display: flex;
    align-items: center;
  
    .brief-info {
      color: #fff;
      @media (max-width: 599px) {
        font-size: large;
        padding: 20px;
      }
  
      .title {
        display: flex;
        justify-content: center;

        @media (max-width: 599px) {
          font-size: 6vw;
          justify-content: left;
        }
        @media (min-width: 600px) {
          font-size: medium;
        }
        @media (min-width: 900px) {
          font-size: x-large;
        }
        @media (min-width: 1200px) {
          font-size: x-large;
        }
        @media (min-width: 1800px) {
          font-size: x-large;
        }
      }
  
      .name {
        display: flex;
        justify-content: center;

        @media (max-width: 599px) {
          font-size: 6vw;
          justify-content: left;
        }
        @media (min-width: 600px) {
          font-size: 30px;
        }
        @media (min-width: 900px) {
          font-size: 60px;
        }
        @media (min-width: 1200px) {
          font-size: 60px;
        }
        @media (min-width: 1800px) {
          font-size: 60px;
        }
      }
  
    }
  
    .photo {
      display: flex;
      justify-content: center;

      img {
        @media (max-width: 599px) {
          height: 150px;
        }
        @media (min-width: 600px) {
          height: 150px;
        }
        @media (min-width: 900px) {
          height: 350px;
        }
        @media (min-width: 1200px) {
          height: 350px;
        }
        @media (min-width: 1800px) {
          height: 350px;
        }
      }
    }
  
  }

  .summary-panel {
    color: #303030;

    @media (max-width: 599px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    @media (min-width: 600px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    @media (min-width: 900px) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    @media (min-width: 1200px) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    @media (min-width: 1800px) {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    .sum-part-1 {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;

      @media (max-width: 599px) {
        font-size: medium;
        font-weight: 600;
      }
      @media (min-width: 600px) {
        font-size: x-large;
        font-weight: 600;
      }
      @media (min-width: 900px) {
        font-size: x-large;
        font-weight: 600;
      }
      @media (min-width: 1200px) {
        font-size: xx-large;
        font-weight: 600;
      }
      @media (min-width: 1800px) {
        font-size: xx-large;
        font-weight: 600;
      }
    }

    .sum-part-2 {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;

      @media (max-width: 599px) {
        font-weight: 200;
        font-size: medium;
      }
      @media (min-width: 600px) {
        font-weight: 200;
        font-size: medium;
        
      }
      @media (min-width: 900px) {
        font-weight: 200;
        font-size: large;
      }
      @media (min-width: 1200px) {
        font-size: large;
        font-weight: 200;
      }
      @media (min-width: 1800px) {
        font-size: large;
        font-weight: 200;
      }
    }

    .sum-part-3 {
      display: flex;
      justify-content: center;

      @media (max-width: 599px) {
        font-weight: 200;
        font-size: medium;
      }
      @media (min-width: 600px) {
        font-weight: 200;
        font-size: medium;
        
      }
      @media (min-width: 900px) {
        font-weight: 200;
        font-size: large;
      }
      @media (min-width: 1200px) {
        font-size: large;
        font-weight: 200;
      }
      @media (min-width: 1800px) {
        font-size: large;
        font-weight: 200;
      }
    }

  }

}