.footer-component {
    background-color: #a8a8a8;
    color: #fff;
    text-align: center;

    @media (max-width: 599px) {
        font-size: x-small;
        font-weight: 500;
        padding: 20px;
    }
    @media (min-width: 600px) {
        font-size: small;
        font-weight: 500;
        padding: 40px;
    }
    @media (min-width: 900px) {
        font-size: medium;
        font-weight: 500;
        padding: 40px;
    }
    @media (min-width: 1200px) {
        font-size: medium;
        font-weight: 500;
        padding: 40px;
    }
    @media (min-width: 1800px) {
        font-size: medium;
        font-weight: 500;
        padding: 40px;
    }

    .numbers {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;

        .number-descriptor {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #fff;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            padding-left: 10px;
            padding-right: 10px;
            font-size: x-small;
            font-weight: 600;
        }

        .number {
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 5px;
            padding-right: 10px;
        }
    }

    .accepted-payments {
        padding: 20px;
        padding-bottom: 0;

        img {
            width: 65px;

            @media (max-width: 599px) {
                width: 45px;
                padding-left: 0;
                padding-right: 0;
            }
        }

    }
}